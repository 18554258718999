import { BrxLinkOpenInTabWithColors } from '@mediashop/base/pattern/atom/LinkOpenInTab';
import { useIntl } from 'react-intl';
import { Link } from 'react-router';
import { useEffect, useState } from 'react';

import { BaseProps, BackgroundProps, BrxImageSetImpl, BrxLink } from '@mediashop/app/bloomreach/types';
import MainNavigationVerticalBrandMenu, { MainNavigationVerticalBrandNavigationItems } from './Menu';
import BackgroundColorWrapper from '@mediashop/base/pattern/atom/BackgroundColorWrapper';
import { EMPTY_STRING } from '@mediashop/app/constants/semanticConstants';
import { TrackableLink } from '@mediashop/app/analytics/components/TrackableLink';
import { TrackingKeys } from '@mediashop/app/analytics/constants/trackingKeys';
import ContentWrapper from '@mediashop/base/pattern/atom/ContentWrapper';
import useReactRouterLink from '@mediashop/app/hooks/useReactRouterLink';
import { BrXImageVariant } from '@mediashop/base/types/imageVariants';
import { injectComponent } from '@mediashop/app/component-injector';
import { useCustomer } from '@mediashop/app/hooks/api/useCustomer';
import { useCart } from '@mediashop/app/hooks/useCart';
import Icon from '@mediashop/base/pattern/atom/Icon';
import useRoute from '@mediashop/app/hooks/useRoute';
import { LoginDropdown } from '@mediashop/catalog-base/pattern/organism/MainNavigation/LoginDropdown';
import VoucherCheck from '@mediashop/catalog-base/pattern/molecule/VoucherCheck';
import BloomreachImageResponsive from '@mediashop/base/pattern/atom/BloomreachImageResponsive';
import CountrySwitch from '@mediashop/base/pattern/molecule/CountrySwitch';
import useDeviceType from '@mediashop/app/hooks/useDeviceType';
import { BreakpointName } from '@mediashop/app/config/breakpoints';

const componentName = 'main-navigation-vertical-brand';

type Props = BaseProps & {
    mainNavigation: {
        background: BackgroundProps;
        linksForLoggedInUsers: BrxLink[];
        msLogoDesktop: BrxImageSetImpl;
        msLogoMobile: BrxImageSetImpl;
        offCanvasLinksWithColorsMobile: BrxLinkOpenInTabWithColors[];
        quickLinkWithColorsDesktop?: BrxLinkOpenInTabWithColors;
        quickLinksWithColorsMobile: BrxLinkOpenInTabWithColors[];
        userIconLinkLoggedIn: BrxLink;
        userIconLinkLoggedOut: BrxLink;
        minWidthMobileLogo?: string;
        minWidthDesktopLogo?: string;
    };
    extendedNavigation: MainNavigationVerticalBrandNavigationItems[];
};

const MainNavigationVerticalBrand = ({ mainNavigation, extendedNavigation }: Props) => {
    const { msLogoDesktop, msLogoMobile, background, linksForLoggedInUsers, minWidthDesktopLogo, minWidthMobileLogo } =
        mainNavigation;

    const filteredExtendedNavigation = extendedNavigation.map((item) => ({
        ...item,
        childItems: item.childItems?.filter((childItem) => !childItem.hide) ?? [],
    }));

    const intl = useIntl();
    const customer = useCustomer();
    const { cart } = useCart();
    const { formatLinkBase } = useReactRouterLink();
    const cartRoute = useRoute('Frontend.Master.Cart');

    const deviceType = useDeviceType();
    const [device, setDevice] = useState<BreakpointName>('desktop');
    useEffect(() => setDevice(deviceType), [deviceType]);

    const isMobile = device === 'mobile';

    let logoStyle = {};
    if (isMobile && minWidthMobileLogo) {
        logoStyle = { minWidth: `${minWidthMobileLogo}px` };
    } else if (!isMobile && minWidthDesktopLogo) {
        logoStyle = { minWidth: `${minWidthDesktopLogo}px` };
    }

    const homeLink = formatLinkBase(EMPTY_STRING);
    const cartLink = formatLinkBase(cartRoute);

    const [isMobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);

    const isLoggedInCustomer = Boolean(customer);
    const loginOrAccountOverviewLink = isLoggedInCustomer
        ? formatLinkBase(mainNavigation.userIconLinkLoggedIn.reference)
        : formatLinkBase(mainNavigation.userIconLinkLoggedOut.reference);

    const toggleMobileMenuOpen = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
        if (isMobileMenuOpen) {
            const scrollY = parseInt(document.body.style.top, 10);
            document.querySelector('body')?.classList.remove('scroll-lock--modal');
            window.scrollTo(0, Math.abs(scrollY));
            document.body.style.top = `0px`;
        } else {
            const scrollY = window.scrollY;
            document.querySelector('body')?.classList.add('scroll-lock--modal');
            document.body.style.top = `-${scrollY}px`;
        }
    };

    return (
        <div className={`${componentName}__wrapper`}>
            <BackgroundColorWrapper backgroundColor={background ?? 'default'} className={componentName}>
                <ContentWrapper>
                    <div className={`${componentName}__content`}>
                        <VoucherCheck />

                        {/* Logo */}
                        <div className={`${componentName}__logo-container`}>
                            <div className={`${componentName}__burger-menu`} onClick={() => toggleMobileMenuOpen()}>
                                {isMobileMenuOpen ? <Icon name="BurgerMenuClose" /> : <Icon name="BurgerMenu" />}
                            </div>

                            <TrackableLink
                                to={homeLink}
                                aria-label={intl.formatMessage({ id: 'mainNavigation.logoLinkLabel' })}
                                trackingKey={TrackingKeys.HEADER_LOGO}
                            >
                                <BloomreachImageResponsive
                                    alt={intl.formatMessage({ id: 'mainNavigation.logoLinkLabel' })}
                                    desktopImage={msLogoDesktop}
                                    mobileImage={msLogoMobile}
                                    variant={BrXImageVariant.MainNavigation}
                                    style={logoStyle}
                                />
                            </TrackableLink>
                        </div>
                        {/* Navigation */}
                        <MainNavigationVerticalBrandMenu
                            extendedNavigation={filteredExtendedNavigation}
                            isMobileMenuOpen={isMobileMenuOpen}
                            toggleMobileMenuOpen={toggleMobileMenuOpen}
                        />
                        {/* Icons */}
                        <div className={`${componentName}__icon-wrapper`}>
                            <CountrySwitch icon="ArrowDownFilled" />
                            {isLoggedInCustomer ? (
                                <div className={`${componentName}__login-wrapper`}>
                                    <Link
                                        to={loginOrAccountOverviewLink}
                                        aria-label={intl.formatMessage({ id: 'mainNavigation.accountLinkLabel' })}
                                    >
                                        <Icon name="AccountFilledVerticalBrand" className={`${componentName}__icon`} />
                                    </Link>
                                    <LoginDropdown links={linksForLoggedInUsers} />
                                </div>
                            ) : (
                                <Link
                                    className={`${componentName}__user-link`}
                                    to={loginOrAccountOverviewLink}
                                    aria-label={intl.formatMessage({ id: 'mainNavigation.accountLinkLabel' })}
                                >
                                    <Icon name="AccountVerticalBrand" />
                                </Link>
                            )}
                            <Link className={`${componentName}__cart-link`} to={cartLink}>
                                <Icon name="CartVerticalBrand" />
                                <div className={`${componentName}__cart-item-amount`}>
                                    {cart?.totalLineItemQuantity ?? 0}
                                </div>
                            </Link>
                        </div>
                    </div>
                </ContentWrapper>
            </BackgroundColorWrapper>
        </div>
    );
};

export default injectComponent(
    'pattern.organism.MainNavigationVerticalBrand',
    MainNavigationVerticalBrand,
    'vertical-brand-base'
);
