import classNames from 'classnames';
import { useMemo, useRef } from 'react';
import { useLocation } from 'react-router';
import Icon from '@mediashop/base/pattern/atom/Icon';
import { MainNavigationVerticalBrandNavigationItems } from '.';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import ContentWrapper from '@mediashop/base/pattern/atom/ContentWrapper';
import { LinkOpenInTab } from '@mediashop/base/pattern/atom/LinkOpenInTab';
import { getGroupedNavigationItems } from '../functions';
import DesktopMenuChildItemLinkText from './DesktopMenuChildItemLinkText';
import { useProject } from '@mediashop/app/hooks/useProject';
import { removeLocaleFromPathname } from '@mediashop/app/helper/localeHelper';
import { markChildItemActive } from './functions';

const componentName = 'main-navigation-vertical-menu';

type Props = {
    extendedNavigation: MainNavigationVerticalBrandNavigationItems[];
    hoveredMainNavigationItem?: MainNavigationVerticalBrandNavigationItems;
    activeMainNavigationItem?: MainNavigationVerticalBrandNavigationItems;
    childMenuVisible: boolean;
    hoverMainNavigationItem: (navItem: MainNavigationVerticalBrandNavigationItems) => void;
    unhoverMainNavigationItem: () => void;
    determineActiveNavigationItem: () => void;
    hoverChildNavigationItem: () => void;
    unhoverChildNavigationItem: (navItem: MainNavigationVerticalBrandNavigationItems) => void;
};

const DesktopMenu = ({
    extendedNavigation,
    hoveredMainNavigationItem,
    activeMainNavigationItem,
    childMenuVisible,
    hoverChildNavigationItem,
    hoverMainNavigationItem,
    unhoverMainNavigationItem,
    determineActiveNavigationItem,
    unhoverChildNavigationItem,
}: Props): JSX.Element => {
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const location = useLocation();
    const { takeLanguageFromUrl } = useProject();
    const cleanedPathname = takeLanguageFromUrl ? removeLocaleFromPathname(location.pathname) : location.pathname;

    const groupedNavigationItems = useMemo(() => {
        const childNavigationItemsOfHoveredMainNavigationItem = extendedNavigation.filter(
            (extNav) => extNav.link === hoveredMainNavigationItem?.link
        );
        return getGroupedNavigationItems(childNavigationItemsOfHoveredMainNavigationItem);
    }, [extendedNavigation, hoveredMainNavigationItem]);

    const hasGroupedItems = groupedNavigationItems.some((items) => items.some((item) => item.newColumn));

    const removeBackdrop = () => {
        document.documentElement.style.setProperty('--overlay-background-top', '0px');
        document.querySelector('body')?.classList.remove(`${componentName}__backdrop`);
    };

    const hoverMainNavigationItemInternal = (navItem: MainNavigationVerticalBrandNavigationItems) => {
        hoverMainNavigationItem(navItem);

        if (wrapperRef.current && navItem.childItems.length) {
            document.documentElement.style.setProperty(
                '--overlay-background-top',
                `${wrapperRef.current.getBoundingClientRect().top}px`
            );
            document.querySelector('body')?.classList.add(`${componentName}__backdrop`);
        }
    };

    const unhoverMainNavigationItemInternal = () => {
        unhoverMainNavigationItem();
        removeBackdrop();
    };

    return (
        <div ref={wrapperRef} className={`${componentName}__desktop-wrapper`}>
            {/**
             * Main Navigation
             */}
            {extendedNavigation.map((navItem) => (
                <div
                    key={navItem.link}
                    onMouseEnter={() => hoverMainNavigationItemInternal(navItem)}
                    onMouseLeave={() => unhoverMainNavigationItemInternal()}
                    onClick={() => determineActiveNavigationItem()}
                >
                    <div
                        className={classNames(
                            {
                                [`${componentName}__main-navigation-link-active`]:
                                    activeMainNavigationItem?.link === navItem.link,
                            },
                            {
                                [`${componentName}__main-navigation-link-hovered`]:
                                    hoveredMainNavigationItem?.link === navItem.link,
                            },
                            `${componentName}__main-navigation-link`
                        )}
                    >
                        <LinkOpenInTab tab={navItem.tab} link={navItem.link!} onClick={removeBackdrop}>
                            <span className={`${componentName}__desktop-wrapper--link-text`}>{navItem.linkText}</span>
                            {navItem.childItems.length > 0 ? (
                                <Icon name="ArrowDown" className={`${componentName}__arrow-down`} />
                            ) : (
                                SKIP_RENDER
                            )}
                        </LinkOpenInTab>
                    </div>
                    {/**
                     * Child Navigation
                     */}
                    {childMenuVisible && hoveredMainNavigationItem === navItem && navItem.childItems.length > 0 ? (
                        <div className={`${componentName}__child-navigation-wrapper`}>
                            <div
                                className={`${componentName}__child-navigation`}
                                onMouseEnter={hoverChildNavigationItem}
                                onMouseLeave={() => unhoverChildNavigationItem(navItem)}
                            >
                                <ContentWrapper>
                                    <div
                                        className={classNames(
                                            `${componentName}__desktop-wrapper--child-menu-container`,
                                            {
                                                [`${componentName}__desktop-wrapper--child-menu-container--with-child-heading`]:
                                                    groupedNavigationItems.length > 1,
                                            }
                                        )}
                                    >
                                        {groupedNavigationItems.map((group, groupIndex) => (
                                            <ul
                                                key={groupIndex}
                                                className={classNames(`${componentName}__navigation-list`, {
                                                    [`${componentName}__navigation-list--with-child-heading`]:
                                                        hasGroupedItems,
                                                })}
                                            >
                                                {group.map((childItem) => (
                                                    <li
                                                        key={childItem.link}
                                                        onClick={removeBackdrop}
                                                        className={classNames(
                                                            `${componentName}__navigation-list-item`,
                                                            {
                                                                [`${componentName}__child-navigation-link-active`]:
                                                                    markChildItemActive(
                                                                        extendedNavigation,
                                                                        childItem,
                                                                        cleanedPathname,
                                                                        location
                                                                    ),
                                                            },
                                                            {
                                                                [`${componentName}__navigation-list-item--headline`]:
                                                                    childItem.isHeadline,
                                                            }
                                                        )}
                                                    >
                                                        <DesktopMenuChildItemLinkText
                                                            childItem={childItem}
                                                            className="cursor-pointer"
                                                        />
                                                    </li>
                                                ))}
                                            </ul>
                                        ))}
                                    </div>
                                </ContentWrapper>
                            </div>
                        </div>
                    ) : (
                        SKIP_RENDER
                    )}
                </div>
            ))}
        </div>
    );
};

export default DesktopMenu;
